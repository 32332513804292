import React from "react"

export const Tools = (props) => {

  const data = [
    {
      id: 1,
      title: 'Suporte',
      url: 'https://tawk.to/chat/61c0b0dfc82c976b71c25f31/1fncb5q76',
      icon: 'fa fa-comment'
    },
    {
      id: 2,
      title: 'Recarga',
      url: 'https://drive.google.com/file/d/1o8qP08bAOr6Ud0ISMcvOssL-htSpPmUm/view',
      icon: 'fa fa-usd'
    },
    {
      id: 2,
      title: 'Office',
      url: 'https://gestor.tv/',
      icon: "fa fa-briefcase",
    },
    {
      id: 3,
      title: 'Abrir chamado',
      url: 'https://tawk.to/chat/61c0b0dfc82c976b71c25f31/1fncb5q76',
      icon: 'fa fa-comments-o'
    }
  ]

  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Ferramentas</h2>
        </div>
        <div className='row'>
          {data
            ? data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                {' '}
                <i className={d.icon}></i>
                <h3>  <a
                  href={d.url}
                  target="_blank"
                  className='btn btn-custom btn-lg page-scroll' rel="noreferrer"
                >
                  {d.title}{' '}
                  <i
                    style={{ fontSize: '16px', padding: 0, background: 'transparent', width: 10, height: 10 }}
                   className="fa fa-external-link"></i>
                </a></h3>
              </div>
            ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
