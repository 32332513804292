import React from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Tools } from "./tools";
import { Tutorials } from "./tutorials";
import SmoothScroll from "smooth-scroll";
import "../App.css";
export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


const App = () => {
    return (
        <div>
            <Navigation />
            <Header />
            <Tools />
            <Tutorials />
        </div >
    );
};

export default App;
