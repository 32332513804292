export const Tutorials = (props) => {

  const data = [
    {
      "icon": "fa fa-youtube-play",
      "name": "Samsung",
      "text": "Como instalar o iptv na SmarTv samsung",
      "url": "https://www.youtube.com/results?search_query=como+instalar+iptv+na+smartv+samsung"
    },
    {
      "icon": "fa fa-youtube-play",
      "name": "SmarTv LG",
      "text": "Como instalar IPTV na SmarTv LG",
      "url": "https://www.youtube.com/results?search_query=como+instalar+iptv+na+smartv+lg"
    },
    {
      "icon": "fa fa-android",
      "name": "Tv Android",
      "text": "Como instalar P2P na SmarTv android",
      "url": "https://www.youtube.com/results?search_query=como+instalar+iptv+na+smartv+lg"
    }
  ];

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Tutoriais</h2>
        </div>
        <div className='row'>
          {data
            ? data.map((d, i) => (
              <a key={`${d.name}-${i}`} href={d.url} target='_blank' rel="noreferrer">
                <div  className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>

                  <div className='service-desc'>
                    <h3 style={{ textDecoration: "underline" }}>{d.name}{' '} <i
                      style={{ fontSize: '16px', padding: 0, background: 'transparent', width: 10, height: 10 }}
                      className="fa fa-external-link"></i></h3>
                    <p>{d.text}</p>
                  </div>
                </div></a>
            ))
            : 'loading'}
        </div>
      </div>
    </div >
  )
}
