import React from "react";
import "../App.css";
import Home from "../components/home";
import { useNavigate } from "react-router-dom";

function Painel() {
  const navigate = useNavigate();
  if (
    !(sessionStorage.getItem("username") && sessionStorage.getItem("password"))
  ) {
    navigate("/auth/login");
  }
  return (
    <>
      <div className="mt--5 App">
        <div className="mx-5 mb-2">
          <Home />
        </div>
      </div>
    </>
  );
}

export default Painel;
