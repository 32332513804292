import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

export const Navigation = (props) => {
  const [auth, setAuth] = useState(true)
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    setAuth(false)
  };

  useEffect(() => {
    if (auth === false) {
      return navigate('/auth/login')
    }

    if(!(sessionStorage.getItem("username") && sessionStorage.getItem("password"))){
      return navigate('/auth/login')
    }
  }, [auth, navigate])

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            Suporte TV
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                Ferramentas
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Tutoriais
              </a>
            </li>
            <li>
              <a href="''" className='page-scroll'>
                <Button
                  onClick={() => { logout() }}
                  color="danger"
                  size="md"
                  style={{ marginTop: -8, borderRadius: 100 }}
                >
                  Sair
                </Button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
