export const Header = () => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  Suporte TV
                </h1>
                <div  style={{ fontWeight: "bold", color: 'white', marginBottom: '20px', fontSize: '14px', backgroundColor: "" }}>
                  {`Atenção não informamos contas de pagamento você deve consultar através do Telegram.
                  Por questões de segurança, em todos os chat’s você deverá colocar seu token e o numero
                  do dia informado no telegram somente com esses dados você irá conseguir acessar nossas funcionalidades.`}</div>
                <a
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Abrir telegram
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
