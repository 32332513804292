import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import api from "../services/api_iptv";
import { useNavigate } from "react-router-dom";
import { DualRing } from 'react-awesome-spinners'

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const encrypt = (text) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const login = async () => {
    setLoading(true);
    if (username && password) {
      await api
        .get(
          `?auth=login&username=${encrypt(username)}&password=${encrypt(
            password
          )}`
        )
        .then((auth) => {
          if (auth.data.username && auth.data.password) {
            sessionStorage.setItem("username", encrypt(auth.data.username));
            sessionStorage.setItem("password", encrypt(auth.data.password));
            setRedirect(true);
          } else {
            if (auth.data.flood) {
              alert(
                "Ops!, Você errou 3 vezes a sua senha, tente novamente daqui a 15 minutos!"
              );
            } else {
              alert("Ops! Usuário ou senha invalido!");
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          alert("Erro Tente mais tarde!");
          setLoading(false);
        });
    } else {
      alert("Preencha todos os campos!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (redirect) {
      sessionStorage.setItem("auth", true);
      navigate("/panel/home");
    }
  }, [navigate, redirect]);

  useEffect(() => { }, [username, password]);

  return (
    <div>
      <h3>Entrar</h3>

      <div className="mb-3">
        <label>Usuário</label>
        <input
          type="text"
          className="form-control"
          placeholder=""
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label>Senha</label>
        <input
          type="password"
          className="form-control"
          placeholder=""
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {loading === true ? (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, paddingTop: 20, fontSize: 14, color: 'primary' }}>
          <DualRing size="32" /><span style={{ paddingTop: 5, fontSize: 14, color: 'primary' }} className="text-primary">Carregando por favor aguarde...</span>
        </div>

      ) : (
        <div className="d-flex align-items-center">
          <button onClick={() => login()}
            className=" tn btn-custom btn-lg page-scroll d-flex align-items-center"
            style={{
              marginTop: 50,
              display: 'flex',
              marginLeft: "30%",
            }}
          >
            Entrar
          </button>
        </div>
      )
      }

    </div>
  );
};
export default Login;
