import React from "react";
import "../App.css";
import Login from "../components/login";
import { useNavigate } from "react-router-dom";

function Auth() {
  const navigate = useNavigate();
  if (
    !(sessionStorage.getItem("username") && sessionStorage.getItem("password"))
  ) {
    navigate("/auth/login");
  }
  return (
    <div className="App">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <h1 className="navbar-brand page-scroll" style={{color: 'white', fontSize: 32}}>
            Suporte TV
          </h1>{" "}
        </div>
      </div>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Login />
        </div>
      </div>
    </div>
  );
}

export default Auth;
